.language-selector {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    span {
        cursor: pointer;
        transform: scale(1);
        transition: all 0.2s;
        backface-visibility: hidden;
        overflow: hidden;
        user-select: none;

        &.active {
            color: #ff4b36
        }

        &:hover {
            transform: scale(1.1);
        }
    }

    

    span + b, b + span {
        margin-left: 8px;
    }
}

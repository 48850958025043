.navigation {
    .language-selector {
        display: none;
    }
    span,
    a {
        user-select: none;
        display: block;
        position: relative;

        &:before {
            display: inline-block;
            content: "";
            height: 3px;
            background: #ff4b36;
            width: 0;
            position: absolute;
            top: calc(100% + 10px);
            left: 0;
            transition: all 0.3s;
        }

        &:hover {
            color: #ff4b36;

            &:before {
                width: 100%;
            }
        }
    }

    &.navigation-footer {
        span,
        a {
            &:before {
                display: none;
            }
        }
    }

    ul {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            padding: 0 15px;

            &.active {
                span,
                a {
                    color: #ff4b36;

                    &:before {
                        width: 100%;
                    }
                }
            }
        }
    }
}

.menu-toggler {
    border-radius: 5px;
    cursor: pointer;
    z-index: 9999;
    color: #fff;
    display: none;
    user-select: none;
    position: relative;
    width: 30px;
    height: 28px;

    svg {
        margin-bottom: -2px;
    }

    span,
    span:before,
    span:after {
        display: inline-block;
        content: "";
        width: 24px;
        height: 3px;
        background: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: all 0.3s;
    }

    span:before {
        margin-top: -7px;
    }

    span:after {
        margin-top: 8px;
    }

    .active {
        background: transparent;

        &:after,
        &:before {
            background: #ff4b36;
            margin: 0;
        }
        &:before {
            transform: translate(-50%, -50%) rotate(-45deg);
        }
        &:after {
            transform: translate(-50%, -50%) rotate(45deg);
        }
    }
}

@media only screen and (max-width: 768px) {
    .menu-toggler {
        display: inline-block;
    }

    .navigation {
        z-index: 5;
        bottom: 0;
        overflow: hidden;
        visibility: hidden;
        opacity: 0;
        transition: all 0.3s;
        position: fixed;
        left: 0;
        width: 100%;
        background-color: rgba(43, 43, 43, 0.95);
        scroll-behavior: smooth;
        padding: 16px;

        &.active {
            max-height: 100%;
            overflow: auto;
            visibility: visible;
            opacity: 1;
            top: 54px;
            background-color: #2b2b2b;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-bottom: 70px;
        }

        .language-selector {
            position: absolute;
            bottom: 20px;
            right: 20px;
            display: flex;
        }

        ul {
            display: block;
            text-align: center;

            li.active,
            li {
                a,
                span {
                    padding: 12px 16px;
                    &:before {
                        display: none;
                    }

                    &:before,
                    &:after {
                        display: inline-block;
                        content: "";
                        width: 10px;
                        height: 2px;
                        background: #fff;
                        position: absolute;
                        left: auto;
                        top: 50%;
                    }

                    &:before {
                        margin-left: -18px;
                    }
                    &:after {
                        margin-left: 8px;
                    }
                }
            }
        }
    }
}

.company-image-wrapper {
    height: 400px;
    background-image: url("../../../public/assets/busy-business-people-walking.jpg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    @media screen and (max-width: 768px) {
        height: 200px;
    }
}

.employee {
    background: #2b2b2b;
    text-align: center;
    padding: 40px 24px;
    color: #fff;
    position: relative;
    transition: all 0.3s;
    z-index: 2;
    overflow: hidden;

    &.cursor-pointer {
        cursor: pointer;
    }

    .icon-linkedin {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
    }
    @media screen and (max-width: 768px) {
        border-bottom: 2px solid #ff4b36;
    }

    &-image {
        width: 180px;
        height: 180px;
        border-radius: 50%;
        background: rgb(43 43 43 / 50%);
        overflow: hidden;
        margin: 0 auto;
        margin-bottom: 16px;

        img {
            width: 100%;
        }
    }

    .linkedin-link {
        font-size: 30px;
    }

    &:hover {
        background: #ff4b36;

        .circles-wrap {
            opacity: 1;
            transform: scale(1);
        }

        @media screen and (max-width: 1024px) {
            background: #2b2b2b;

            .circles-wrap {
                opacity: 0;
                transform: scale(0);
            }
        }
    }

    .circles-wrap {
        opacity: 0;
        position: absolute;
        bottom: -34%;
        left: auto;
        z-index: -1;
        right: -32%;
        transition: all 0.3s;
        transform: scale(0);

        .circles {
            height: 400px;
            width: 400px;
            background: transparent;
            position: relative;
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: center;

            span.circle {
                height: 400px;
                width: 400px;
                background: transparent;
                position: relative;
                display: inline-block;
                z-index: 1;
                border-radius: 50%;
                position: absolute;

                &-1 {
                    height: 100px;
                    width: 100px;
                    background: #fff4ef;
                    z-index: 5;
                    display: inline-block;
                    z-index: 5;
                }

                &-2 {
                    height: 200px;
                    width: 200px;
                    background: #f3d8d7;
                    z-index: 4;
                }

                &-3 {
                    height: 300px;
                    width: 300px;
                    background: #dd9494;
                    z-index: 3;
                }

                &-4 {
                    height: 400px;
                    width: 400px;
                    background: #966867;
                    z-index: 2;
                }
            }
        }
    }
}

.company-values {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 150px;

    .values-chart {
        width: fit-content;

        .circle {
            position: relative;
            width: 400px;
            height: 400px;
            border: 1px dashed #ff4b36;
            border-radius: 50%;

            .value {
                position: absolute;

                .value-text {
                    position: absolute;
                    width: 200px;

                    p {
                        font-size: 13px;
                    }
                }

                &-1 {
                    top: -50px;
                    left: 150px;

                    .value-text {
                        left: -50px;
                        top: -105%;
                    }
                }

                &-2 {
                    top: 80px;
                    left: -10%;

                    .value-text {
                        left: -215%;
                        top: -0%;
                        text-align: right;
                    }
                }

                &-3 {
                    top: 80px;
                    left: 85%;

                    .value-text {
                        left: 115%;
                        top: 0%;
                        text-align: left;
                    }
                }

                &-4 {
                    top: 290px;
                    left: 5%;

                    .value-text {
                        left: -215%;
                        top: 0%;
                        text-align: right;
                    }
                }

                &-5 {
                    top: 290px;
                    left: 70%;

                    .value-text {
                        left: 115%;
                        top: 0%;
                    }
                }

                .value-circle {
                    width: 100px;
                    height: 100px;
                    background: #ccc;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: 0.2s background;

                    img {
                        width: 50px;
                        height: auto;

                        &.alt-img {
                            display: none;
                        }
                    }

                    &:hover {
                        background: #ff4b36;

                        .main-img {
                            display: none;
                        }

                        .alt-img {
                            display: block;
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 991px) {
        padding-top: 30px;

        .values-chart {
            .circle {
                border-width: 0;
                width: auto;
                height: auto;

                .value {
                    position: relative;
                    top: unset;
                    left: unset;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column-reverse;
                    margin-bottom: 40px;

                    &::after {
                        content: "";
                        position: absolute;
                        height: 30px;
                        border-right: 1px dashed #ff4b36;
                        bottom: -25px;
                    }

                    &:last-of-type {
                        margin-bottom: 0;
                        &::after {
                            display: none;
                        }
                    }

                    .value-text {
                        top: unset;
                        left: unset;
                        position: relative;
                        text-align: center;
                        width: unset;
                        padding: 0 20px;
                    }
                }
            }
        }
    }
}

.section-about {
    .contact-banner {
        margin: 0 0 50px;
    }

    @media screen and (max-width: 768px) {
        margin: 0;
        padding: 0;
    }
}

:root {
    --dice-f: rgba(255, 255, 255, 1);
}

.dice-container {
    margin: 20px auto;
    width: 100%;
}

.dice-scene {
    margin: auto auto;
    width: 60px;
    height: 60px;
    perspective: 300px;
    transform: scale(0.9);
}

.dice {
    width: 100%;
    height: 100%;
    position: relative;
    transform-style: preserve-3d;
    /* Resolve distortion */
    transform: translateZ(-25px);
    transition: transform 1s;
    transform: scale(0.9);
}

/* Effect */
.dice__face {
    position: absolute;
    background: var(--dice-f);
    width: 60px;
    height: 60px;
    border-radius: 5px;
    display: flex;
    box-shadow: 0px 10px 35px -15px rgba(255, 255, 255, 0.8);
}

/* TranslateZ = (face's width / 2) */

/* Face 1 */
.dice__face.front {
    transform: rotateY(0) translateZ(30px);
}

/* Face 2*/
.dice__face.back {
    transform: rotateY(180deg) translateZ(30px);
}

/* Face 5 */
.dice__face.top {
    transform: rotateX(90deg) translateZ(30px);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
}

/* Face 6 */
.dice__face.bottom {
    transform: rotateX(-90deg) translateZ(30px);
    display: grid;
    grid-template-columns: 1fr 1fr;
}

/* Face 3 */
.dice__face.left {
    transform: rotateY(-90deg) translateZ(30px);
    display: grid;
}

/* Face 4 */
.dice__face.right {
    transform: rotateY(90deg) translateZ(30px);
    display: grid;
    grid-template-columns: 1fr 1fr;
}

/* Arranging the dots on face 5 */
.dot {
    background: black;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin: auto auto;
}

.dot-1 {
    background: red;
}

.dot-5-m {
    grid-column: 2;
    grid-row: 2;
}

.dot-5-t1 {
    grid-column: 1;
    grid-row: 1;
}

.dot-5-t2 {
    grid-column: 1;
    grid-row: 3;
}

.dot-5-b1 {
    grid-column: 3;
    grid-row: 1;
}

.dot-5-b2 {
    grid-column: 3;
    grid-row: 3;
}

@media screen and (max-width: 600px) {
    .dice-scene,
    .dice {
        transform: scale(0.7);
    }
}

@keyframes blink {
    0% {
        opacity: 0.2;
    }
    20% {
        opacity: 1;
    }
    100% {
        opacity: 0.2;
    }
}

.loading span {
    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
}

.loading span:nth-child(2) {
    animation-delay: 0.2s;
}

.loading span:nth-child(3) {
    animation-delay: 0.4s;
}

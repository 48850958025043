@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;500&display=swap");

* {
  box-sizing: border-box;
}

img {
  margin: 0;
  max-width: 100%;
}

html {
  background: #f5f0f0;
}

body {
  margin: 0;
  min-width: 320px;
  font-family: "Montserrat";
  background: #f5f0f0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 72px;
  box-sizing: border-box;
}

.main {
  min-height: calc(100vh - 74px);
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

a {
  &,
  &:visited,
  &:active {
    color: unset;
    text-decoration: none;
    transition: all 0.3s;
  }

  &:not(.highlight) {
    &:hover {
      color: #ff4b36;
    }
  }
}

.blank-page-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: calc(100vh - 74px);
  height: calc(100vh - 200px);
  flex-direction: column;
}

.section {
  padding: 0 0 58px;
  .section-head {
    padding: 16px 0;
    margin-bottom: 24px;
  }
}

.form-control {
  border-radius: 0;

  &:focus {
    background-color: #fff;
    border-color: #ff4b36;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(255 75 99 / 19%);
  }
}

.btn {
  border-radius: 0;
  padding: 16px 32px;

  &-primary,
  &-primary.disabled,
  &-primary:disabled,
  &-outline-primary.disabled,
  &-outline-primary:disabled,
  &-outline-primary {
    color: #ff4b36;
    border-color: #ff4b36;

    &:focus {
      box-shadow: 0 0 0 0.25rem rgb(255 75 99 / 19%);
    }

    &:hover {
      color: #fff;
      background-color: #ff4b36;
      border-color: #ff4b36;
    }
  }

  &-outline-light {
    &:hover {
      color: #ff4b36 !important;
    }
  }
}

.input-wrapper {
  position: relative;

  .error {
    display: none;
    margin-bottom: 0;
    font-size: 12px;
    color: #ff4b36;
  }

  &-error {
    .error {
      position: absolute;
      bottom: 0;
      left: 12px;
      display: block;
    }
  }
}

.section {
  h2,
  h3 {
    margin: 32px 0 24px;

    &.highlight {
      background: #ff4b36;
      width: fit-content;
      padding: 5px 10px;
      border-radius: 6px;
      cursor: default;
      color: #fff;
    }
  }
}

.text-highlight {
  color: #ff4b36;
}

.text-justify {
  text-align: justify;
}

.clutch-widget {
  @media screen and (max-width: 768px) {
    width: 550px !important;
  }

  @media screen and (max-width: 575px) {
    position: relative;
    right: -30px;
    width: 320px !important;
  }
}

@media screen and (max-width: 768px) {
  .main {
    min-height: calc(100vh - 54px);
  }
  .blank-page-wrapper {
    min-height: calc(100vh - 54px);
  }
  .section-head {
    padding: 8px 0;
    margin-bottom: 16px;
  }
  body {
    padding-top: 54px;
  }

  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 22px;
  }

  h3 {
    font-size: 20px;
  }

  h4 {
    font-size: 18px;
  }

  h5 {
    font-size: 16px;
  }
  .container:last-child {
    .section-body {
      padding-bottom: 0 !important;
    }
  }
}

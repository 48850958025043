.header {
    background: #2b2b2b;
    color: #f5f0f0;
    width: 100%;
    position: fixed;
    z-index: 10;
    top: 0;

    .logo-wrapper {
        padding: 15px 0;
        cursor: pointer;

        .logo {
            display: flex;
            align-items: center;
            user-select: none;
        }

        img {
            display: inline-block;
        }

        span {
            margin-left: 8px;
            display: inline-block;
        }
    }

    &-inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    @media screen and (max-width: 1024px) {
        .logo-wrapper {
            span {
                display: none;
            }
        }

        .header-actions {
            display: none;
        }
    }

    @media screen and (max-width: 768px) {
        .logo-wrapper {
            padding: 5px 0;
        }

        .header-actions {
            display: none;
        }
    }
}

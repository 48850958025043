.services-row {
    max-width: 1000px;
    margin: 0 auto;

    @media screen and (max-width: 991px) {
        max-width: 700px;
    }
}

.service-head {
    max-width: 800px;
    margin: 0 auto;
    font-size: 20px;

    @media screen and (max-width: 1024px) {
        font-size: 16px;
    }
}
.service {
    margin: 8px;
    height: calc(100% - 16px);

    p {
        margin: 0;
        text-align: justify;
    }

    h4 {
        margin-bottom: 20px;
        text-align: left;
    }

    .circle-outer {
        width: 100%;
        height: 0;
        padding-bottom: 100%;
        position: relative;
        border-radius: 50%;

        &::before,
        &::after {
            box-sizing: inherit;
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 100%;
            top: 0;
            left: 0;
        }

        &::before {
            border: 8px solid #ccc; // We're animating border-color again
        }

        &::after {
            border: 0 solid transparent; // Makes border thinner at the edges? I forgot what I was doing
        }

        &.loaded {
            &::before {
                border-top-color: #ff4b36; // Show borders
                border-right-color: #ff4b36;
                border-bottom-color: #ff4b36;
                transition: border-top-color 1s linear,
                    // Stagger border appearances
                    border-right-color 1s linear 0.1s,
                    border-bottom-color 1s linear 0.2s;
            }

            &::after {
                border-top: 8px solid #ff4b36; // Shows border
                border-left-width: 8px; // Solid edges, invisible borders
                border-right-width: 8px; // Solid edges, invisible borders
                transform: rotate(270deg); // Rotate around circle
                transition: transform 1s linear 0s, border-left-width 0s linear 0.35s; // Solid edge post-rotation
            }
        }

        .circle-content {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            padding: 0 25px;
            font-size: 13px;

            p {
                text-align: center;
            }
        }
    }

    @media screen and (max-width: 991px) {
        padding: 20px 100px;
    }

    @media screen and (max-width: 768px) {
        padding: 20px 50px;
    }

    @media screen and (max-width: 440px) {
        padding: 20px 5px;
    }
}

.guarantee-item {
    display: inline-flex;
    align-items: center;

    svg {
        width: 75px;
        height: 75px;
        margin-bottom: 16px;
        margin-right: 30px;
        color: #ff4b36;
    }

    .guarantee-text {
        h5 {
            color: #ff4b36;
            width: 100%;
            text-align: left;
            font-weight: bold;
        }
    }
}

.video-wrapper {
    overflow: hidden;
    width: 100%;
    max-height: 400px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &::after {
        content: "";
        background: rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 2;
    }

    video {
        position: relative;
        height: auto;
        width: 100%;
        z-index: 1;
    }

    h1 {
        z-index: 3;
        text-align: center;
        color: #fff;
        position: absolute;
        width: 100%;
        word-break: break-word;
        padding: 0 30px;
    }
}

.slick-arrow {
    &:before {
        width: 20px;
        height: 20px;
        content: "";
        border: 4px solid #2b2b2b;
        border-width: 4px 4px 0 0;
        display: inline-block;
        font-family: none;
        transform: rotate(45deg);
    }

    &.slick-prev:before {
        transform: rotate(225deg);
    }
}

.slick-slide {
    transition: all 0.3s;
    &:hover {
        transform: translateY(-10px);
    }
}

.testimonial {
    width: 100%;
    padding: 150px 24px 24px;

    &-inner {
        background: #e7e7e7;
        position: relative;
        padding: 124px 24px 24px;
        text-align: center;
    }

    img {
        width: 200px;
        height: 200px;
        zoom: 1;
        object-fit: cover;
        border-radius: 50%;
        border: 6px solid #f5f0f0;
        position: absolute;
        left: 50%;
        top: -100px;
        transform: translateX(-50%);
    }

    .testimonial-quote-wrapper {
        position: relative;
        padding: 24px 32px;
    }

    blockquote {
        position: relative;
        height: 80px;
        overflow: auto;
        &::-webkit-scrollbar {
            width: 6px;
        }
        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }
        &::-webkit-scrollbar-thumb {
            background: #888;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: #555;
        }
    }

    .testimonial-quote {
        width: 40px;
        height: 40px;
        color: #ff4b36;
        position: absolute;
        top: 0;
        left: 0;

        &-end {
            transform: rotate(180deg);
            top: auto;
            left: auto;
            bottom: 0;
            right: 0;
        }
    }

    p {
        font-size: 18px;
        color: #ff4b36;

        span {
            font-size: 12px;
        }
    }

    @media screen and (max-width: 610px) {
        padding: 150px 0 0;
    }
}
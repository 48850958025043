.footer-wrapper {
    box-shadow: 0 1px 2px #ccc;
    color: #f5f0f0;
    width: 100%;

    .footer-inner {
        padding: 16px 0;
        background: #2b2b2b;
    }

    .footer-copyright {
        padding: 10px 0 5px 0;
        background: #ff4b36;
        height: 50px;
        overflow: hidden;
    }

    .copyright {
        margin: 0;
        position: relative;

        @media screen and (max-width: 575px) {
            display: block !important;
        }
    }

    .socials {
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        li + li {
            margin-left: 32px;
        }

        svg {
            width: 30px;
            height: 30px;
        }

        a:hover {
            color: #ff4b36;
        }
    }

    @media screen and (max-width: 768px) {
        .footer-navigation-wrapper {
            display: none;
        }

        .footer-inner {
            padding: 0;
        }

        .socials {
            svg {
                width: 28px;
                height: 28px;
            }
        }

        .footer-copyright {
            overflow: hidden;
            text-align: center;
            padding: 20px 0;
            margin-bottom: 0;
            height: auto;
            .row {
                flex-direction: column-reverse;
            }

            .clutch-widget {
                margin: 0 auto;
            }
        }
    }
}

.position-item {
    .card-header {
        padding: 0;
    }

    .careers-toggle-button {
        width: 100%;
        border: none;
        padding: 15px;
        font-size: 20px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .description {
        margin-bottom: 30px;
    }

    .position-list {
        .line {
            margin-left: 50px;
        }
    }

    @media screen and (max-width: 768px) {
        .title {
            font-size: 14px;
        }

        .location {
            font-size: 12px;
            text-align: right;
        }

        p {
            font-size: 13px;
        }

        .position-list .line {
            margin-left: 20px;
        }
    }

    @media screen and (max-width: 480px) {
        .location {
            max-width: 100px;
        }
    }
}

.section-contacts {
    .section-head {
        background-position: center right;
        background-size: contain;
        background-repeat: no-repeat;
    }

    .contact-data {
        li {
            min-width: 240px;
            min-height: 120px;
            margin: 0 auto;
            background: #ff4b36;
            color: #fff;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 20px;

            & + li {
                margin-top: 20px;
            }
        }

        .contact-icon {
            padding: 10px;
            display: inline-block;
            vertical-align: baseline;
            position: relative;
            bottom: 2px;

            svg {
                width: 20px;
                height: 20px;
            }
        }

        a {
            padding: 0 10px;
            &:hover,
            &:focus,
            &:active {
                background: #fff;
                color: #ff4b36;
            }
        }
    }

    @media screen and (max-width: 768px) {
        .btn {
            width: 100%;
        }

        .contact-data {
            li {
                width: 100%;
            }
        }
    }

    .form-contacts-wrapper {
        min-height: calc(100vh - 244px);
        align-items: center;
        max-width: 1000px;
        margin: 0 auto;

        .loading {
            &-inner {
                position: relative;
            }

            .loading-overlay {
                opacity: 0;
                visibility: hidden;
                overflow: hidden;
                position: absolute;
                left: calc(50% - 10px);
                top: calc(50% - 30px);
                transform: translate(-50%, -50%);

                z-index: 10;

                .spinner {
                    position: relative;
                    width: 45px;
                    height: 45px;
                    margin: 0 auto;
                    animation: loadingI 2s linear infinite;
                }

                .bubble-1,
                .bubble-2 {
                    position: absolute;
                    top: 0;
                    width: 25px;
                    height: 25px;
                    border-radius: 100%;
                    background-color: #ff4b36;
                    animation: bounce 2s ease-in-out infinite;
                }

                .bubble-2 {
                    top: auto;
                    bottom: 0;
                    animation-delay: -1s;
                }

                @keyframes loadingI {
                    100% {
                        transform: rotate(360deg);
                    }
                }

                @keyframes bounce {
                    0%,
                    100% {
                        transform: scale(0);
                    }
                    50% {
                        transform: scale(1);
                    }
                }
            }

            &-active {
                .loading-overlay {
                    opacity: 1;
                    visibility: visible;
                    overflow: visible;
                }

                textarea,
                label,
                input {
                    pointer-events: none;
                    opacity: 0.7;
                }
            }
        }

        @media screen and (max-width: 991px) {
            padding-top: 24px;
        }
    }
}
